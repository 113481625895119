




import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      counter: 0,
      interval: 0,
    };
  },
  props: {
    timer: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    this.counter = this.timer;
    this.start();
  },
  methods: {
    start() {
      this.interval = setInterval(this.decrease, 1000);
    },
    decrease() {
      this.counter -= 1;

      if (this.counter <= 0) {
        clearInterval(this.interval);

        this.$emit("stop");
      }
    },
  },
  computed: {
    minutes(): number {
      return Math.floor(this.counter / 60);
    },
    seconds(): number {
      return Math.floor(this.counter % 60);
    },
  },
});
