





































import Vue, { PropType } from "vue";
import Event from "@/types/Event";

export default Vue.extend({
  props: {
    event: {
      type: Object as PropType<Event>,
      required: true,
    },
  },
  computed: {
    isMobile(): boolean {
      return this.$vuetify.breakpoint.xs;
    },
    hasBanner(): boolean {
      return this.event.has_imagem_impressao_online;
    },
    banner(): string {
      return `https://office.ingressodevantagens.com.br/evento/${this.event.id}/imagemImpressaoOnline`;
    },
    bannerStyle(): CSSStyleDeclaration {
      return {
        backgroundImage: `url(${this.banner})`,
      } as CSSStyleDeclaration;
    },
    socialBannerStyle(): CSSStyleDeclaration {
      return {
        background: `url(${this.banner})`,
        backgroundPosition: "center",
        width: "100%",
        height: "100%",
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
      } as CSSStyleDeclaration;
    },
    homeLogo(): string {
      return `https://office.ingressodevantagens.com.br/img/evento/${this.event.id}/imagemImpressao`;
    },
    awayLogo(): string {
      return `https://office.ingressodevantagens.com.br/evento/${this.event.id}/imagemEscudoVisitante`;
    },
  },
});
