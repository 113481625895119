








import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "TAlert",

  props: {
    type: {
      type: String as PropType<"warning" | "success" | "danger" | "info">,
      required: true,
    },
    textColor: {
      type: String,
      default: "white",
      required: false,
    },
  },

  computed: {
    cardClass(): string {
      if (this.type === "info") {
        return "t-alert--info";
      }
      return this.type;
    },
  },

  methods: {
    getIcon() {
      return {
        warning: "mdi mdi-alert-box-outline",
        success: "mdi mdi-check-circle-outline",
        danger: "mdi mdi-alert-outline",
        info: "mdi mdi-alert-circle",
      }[this.type];
    },
    isMobile(): boolean {
      return this.$vuetify.breakpoint.xs;
    },
    getStyle() {
      const style = `color: ${this.textColor};`;
      return style;
    },
  },
});
