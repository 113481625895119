





























































































import Vue, { PropType } from "vue";
import QrCode from "qrcode.vue";
import PixPayment from "@/types/PixPayment";
import TCountdown from "@/components/core/TCountdown.vue";
import TAlert from "@/components/core/TAlert.vue";
import { DateTime } from "luxon";

export default Vue.extend({
  name: "PixForm",

  beforeMount() {
    if (this.payment !== null) {
      const expiration = DateTime.fromFormat(this.payment.expiration_date, "y-MM-dd HH:mm:ss");
      const diff = expiration.diff(DateTime.now(), ["seconds"]).toObject();

      if (diff.seconds !== undefined) {
        this.timer = diff.seconds;
      }
    }

    if (this.secondsToTimer) {
      this.timer = this.secondsToTimer;
    }
  },

  data() {
    return {
      timer: 900 as number | null,
      steps: [
        {
          id: 1,
          text: {
            mobile: "Após gerar o código PIX, clique no botão COPIAR CÓDIGO",
            desktop: "Após gerar o código PIX, clique no botão COPIAR CÓDIGO ou LEIA O QR CODE pelo seu smartphone",
          },
        },
        {
          id: 2,
          text: {
            mobile: "Abra o aplicativo do seu banco, vá até a seção PIX e escolha a opção COPIA E COLA",
            desktop: "Abra o aplicativo do seu banco, vá até a seção PIX e informe o código PIX gerado",
          },
        },
        {
          id: 3,
          text: {
            mobile: "Após COLAR verifique os dados do recebedor e o valor da operação para confirmar o pagamento",
            desktop: "Em seguida, verifique os dados do recebedor e o valor da operação para confirmar o pagamento",
          },
        },
        {
          id: 4,
          text: {
            mobile:
              "Em seguida, retorne aqui e aguarde por alguns segundos até a confirmação do seu pagamento e geração automática do seu voucher",
            desktop:
              "Pronto! Retorne aqui e aguarde por alguns segundos até a confirmação do seu pagamento e geração automática do seu voucher",
          },
        },
      ],
    };
  },

  props: {
    pix: {
      type: Object as PropType<PixPayment>,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    secondsToTimer: {
      type: Number,
    },
  },

  methods: {
    submit() {
      this.$emit("submit");
    },
    validate() {
      return true;
    },
    copy() {
      window.navigator.clipboard.writeText(this.code);
      this.$snackbar("Código copiado!", "success");
    },
  },

  components: {
    QrCode,
    TCountdown,
    TAlert,
  },

  computed: {
    isMobile(): boolean {
      return this.$vuetify.breakpoint.smAndDown;
    },
    payment(): PixPayment {
      if (this.pix !== undefined) {
        return this.pix;
      }

      return this.$store.state.cart.payment;
    },
    filter(): string {
      if (this.payment === null || this.payment.br_code === undefined) {
        return "filter: blur(8px)";
      }

      return "";
    },
    code(): string {
      if (this.payment === null) return "PIX";

      return this.payment.br_code ?? "PIX";
    },
    loading(): boolean {
      return this.$loader.loading("button");
    },
  },
});
